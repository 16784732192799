<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"
                     :btn_edit="false"
                     :btn_delete="false"
        >
            <div class="pull-right">
                <el-button type="text" icon="el-icon-coffee-cup" @click="syncTMRooms">同步会议室信息</el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <div class="clearfix"></div>
    </section>
</template>

<script>
    import FrontTable from "../components/front-table";
    import FrontMixins from "../common/mixins/front-mixins";

    export default {
        mixins: [FrontMixins],
        name: 'admin-tm-user',
        components: { FrontTable},
        data() {
            return {
                batch: false,
                payload: {id:'1',max:2},
                rooms: {},
                schedules:[],
            }
        },
        created() {
            this.model = 'Admin.TencentRoom';
        },
        methods: {
            syncTMRooms() {
                this.$affirm("是否开始同步腾讯会议室信息?").then(()=>{
                    this.$api(this.model+'.RoomList',{},1).then(()=>{
                        this.$notice.success('同步完成!');
                        this.reload();
                    });
                })
            },
        }
    }

</script>

<style lang="scss">

</style>
